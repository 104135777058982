/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import getLanguage from '../utils/getLanguage';
import * as api from '../utils/api';
import { showNotification } from '../state/notifications';
import { BlueInfoBox, CalenderLink, STEP } from './utils';
import { EmailFormik } from './Email';
import Link from '../components/Link';
import RegisterWidget from '../components/widgets/RegisterWidget';
import pick from 'lodash/pick';
import Button from '../components/Button';
import { ThankYouToServicePointBox } from './ThankYouToServicePointBox';
import { ThankYouNotReceiveBox } from './ThankYouNotReceiveBox';
import { MapDelivery } from './Map';

export const AgreedDelivery = ({
  data: { shipment = {}, availableDeliverySlots, woltDelivery = {} },
  selection = {},
  token,
  goNext,
  goBack,
}) => {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const language = useSelector(state => getLanguage(state));
  const [emailSent, setEmailSent] = useState(!!selection.email);
  const isLoggedIn = useSelector(state => !!state.session.user);

  const onSendEmail = useCallback(
    async v => {
      try {
        await api.sendDeliveryConfirmationEmail(shipment.shipmentNumber, token, v.email, language);
        setEmailSent(true);
      } catch (err) {
        dispatch(showNotification('genericApiError'));
      }
    },
    [shipment.shipmentNumber, token, dispatch, language]
  );

  const canEdit =
    (availableDeliverySlots?.length > 0 || woltDelivery.available) &&
    !selection?.woltDeliverySelected &&
    !!!selection.notReceive;

  const handleEdit = useCallback(() => {
    goNext(STEP.DELIVERY_INSTRUCTIONS);
  }, [goNext]);

  const canTrackDelivery = !!shipment?.deliveryTime?.canTrackDelivery;

  if (selection?.notReceive === 'service-point') {
    return (
      <ThankYouToServicePointBox
        shipment={shipment}
        pickupPoint={selection?.pickupPoint}
        selectedPickupAtTerminal={selection?.selectedPickupAtTerminal}
      />
    );
  }

  if (selection?.notReceive === 'not-receive') {
    return <ThankYouNotReceiveBox />;
  }

  return (
    <>
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>{translate('delivery.agree.deliveryInfo')}</Styled.h1>

      {canTrackDelivery ? (
        <MapDelivery shipment={shipment} goNext={goNext} token={token} />
      ) : (
        <p>{translate('delivery.agree.shipment', pick(shipment, ['shipmentNumber', 'sender']))}</p>
      )}
      <BlueInfoBox deliveryAddress={shipment.deliveryAddress} {...selection} />
      {selection.doorCode && (
        <p>
          {translate('delivery.agree.doorCode2')} {selection.doorCode}
        </p>
      )}
      {selection.deliveryRemarks && (
        <p>
          {translate('delivery.agree.instructions')} {selection.deliveryRemarks}
        </p>
      )}

      {canEdit && (
        <Button sx={{ mt: 3 }} onClick={handleEdit}>
          {translate('delivery.agree.changeInfo')}
        </Button>
      )}
      <CalenderLink icsBase64={selection.calendarEntry} shipmentNumber={shipment.shipmentNumber} />
      {emailSent ? (
        <p>{translate('delivery.agree.emailSent')}</p>
      ) : (
        <EmailFormik onSubmit={onSendEmail} translate={translate} />
      )}
      {selection?.woltDeliverySelected && selection?.woltTrackingUrl && (
        <div sx={{ mt: 4 }}>
          <a href={selection.woltTrackingUrl} target="_blank">
            {translate('delivery.agree.trackDelivery')}
          </a>
        </div>
      )}
      {isLoggedIn ? (
        <div sx={{ mt: 4 }}>
          <Link to="/my-pages/incoming-parcels" sx={{ textDecoration: 'underline' }}>
            {translate('delivery.agree.return')}
          </Link>
        </div>
      ) : (
        <RegisterWidget />
      )}
    </>
  );
};
