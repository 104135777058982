/** @jsx jsx */
import { jsx, Styled, Box, Flex } from 'theme-ui';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';
import { date2dayString, ButtonBox, NavigationHeader } from './utils';
import Button from '../components/Button';
import Switch from '../components/Switch';
import moment from 'moment-timezone';
import { formatPrice } from '../utils/formatters';
import PersonalHandoverNote from './PersonalHandoverNote';

export const BasicInfo = ({ data, selection, updateSelection, goNext, goBack, token }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const allowedDates = useMemo(
    () =>
      (data.availableDeliverySlots || []).flatMap(a =>
        a.deliverySlots.map(slot => ({ ...slot, date: new Date(a.date) }))
      ),
    [data]
  );

  const [showAllTimes, setShowAllTimes] = useState(false);

  const shownAllowedDates =
    !!allowedDates.length && allowedDates.slice(0, !showAllTimes && allowedDates.length >= 5 ? 4 : allowedDates.length);

  const woltDelivery = useMemo(() => data.woltDelivery, [data]);
  const shipment = useMemo(() => data.shipment, [data]);

  const handleSelectDate = useCallback(
    a =>
      updateSelection({
        day: date2dayString(a.date),
        time: a,
        woltDeliverySelected: false,
        selectionDone: true,
      }),
    [updateSelection]
  );

  const handleSelectWoltDelivery = useCallback(
    () => updateSelection({ woltDeliverySelected: true, unattendedHandover: false, selectionDone: true }),
    [updateSelection]
  );

  const handleShowMore = useCallback(() => {
    setShowAllTimes(true);
  }, []);

  const handleUnattendedHandoverChange = useCallback(
    event => {
      updateSelection({ unattendedHandover: event.target.checked });
    },
    [updateSelection]
  );

  const canProceed = useMemo(() => {
    if (selection?.woltDeliverySelected) return true;
    if (!allowedDates.length) return false;
    if (!selection?.time) return false;
    return true;
  }, [selection, allowedDates]);

  const handleGoNext = useCallback(() => {
    if (!canProceed) {
      return;
    }
    goNext();
  }, [goNext, canProceed]);

  const WoltDeliveryOption = () => {
    return (
      <Box
        onClick={() => handleSelectWoltDelivery()}
        sx={{
          borderStyle: 'solid',
          borderColor: selection.woltDeliverySelected ? 'primary' : 'border',
          alignItems: 'center',
          cursor: 'pointer',
          borderWidth: 2,
          borderRadius: 10,
          px: 3,
          py: 2,
          my: 2,
          flex: '1 1 auto',
        }}
      >
        <Flex sx={{ justifyContent: 'space-between' }}>
          <p sx={{ fontWeight: selection.woltDeliverySelected ? 'bold' : 'normal' }}>
            {translate('delivery.agree.woltDelivery')}
          </p>
          <p sx={{ fontWeight: selection.woltDeliverySelected ? 'bold' : 'normal' }}>
            {formatPrice(woltDelivery.priceVatIncluded)}
          </p>
        </Flex>
      </Box>
    );
  };

  return (
    <>
      <NavigationHeader goBack={goBack} shipmentNumber={shipment?.shipmentNumber} token={token} />
      <Styled.h1 sx={{ color: 'secondary', mb: 4 }}>
        {translate(allowedDates.length ? 'delivery.agree.when' : 'delivery.agree.howToDeliver')}
      </Styled.h1>
      {!!allowedDates.length ? (
        <>
          <Box sx={{ bg: 'blueLighter', px: 3, py: 1, borderRadius: 1 }}>
            <p>{translate('delivery.agree.accurateEstimateNote')}</p>
          </Box>
          <p sx={{ fontWeight: 'medium' }}>{translate('delivery.agree.selectDay')}</p>
        </>
      ) : null}
      {woltDelivery.available && <WoltDeliveryOption />}
      {(shownAllowedDates || [])?.map((date, i) => {
        const isSelected =
          selection &&
          !selection.woltDeliverySelected &&
          selection.time &&
          selection.time.start === date.start &&
          selection.time.end === date.end &&
          selection.day === date2dayString(date.date);
        return <TimeOption key={i} option={date} onSelect={handleSelectDate} isSelected={isSelected} />;
      })}
      {!showAllTimes && allowedDates.length >= 5 && (
        <Box
          onClick={handleShowMore}
          sx={{
            borderStyle: 'solid',
            borderColor: 'border',
            alignItems: 'center',
            cursor: 'pointer',
            borderWidth: 2,
            borderRadius: 1,
            px: 3,
            py: 2,
            my: 2,
          }}
        >
          <p>{translate('delivery.agree.moreTime')}</p>
        </Box>
      )}
      {!selection?.woltDeliverySelected && !shipment?.personalHandover && (
        <Box sx={{ my: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: 'black' }}>
            <span sx={{ flex: 1, mr: 2 }}>{translate('delivery.details.noPresence')}</span>
            <Switch checked={selection.unattendedHandover || false} onChange={handleUnattendedHandoverChange} />
          </Box>
          {selection.unattendedHandover ? (
            <p sx={{ borderRadius: 1, bg: 'blueLighter', p: 3, mt: 4 }}>
              {translate('delivery.details.noPresenceRemark')}
            </p>
          ) : null}
        </Box>
      )}

      <PersonalHandoverNote sxx={{ my: 4 }} shipment={shipment} />

      <ButtonBox>
        <Button sx={{ mt: 4 }} onClick={handleGoNext} disabled={!canProceed}>
          {translate('delivery.agree.continue')}
        </Button>
      </ButtonBox>
    </>
  );
};

const TimeOption = ({ option, onSelect, isSelected }) => {
  return (
    <Box
      onClick={() => onSelect(option)}
      sx={{
        borderStyle: 'solid',
        borderColor: isSelected ? 'primary' : 'border',
        alignItems: 'center',
        cursor: 'pointer',
        borderWidth: 2,
        borderRadius: 1,
        px: 3,
        py: 2,
        my: 2,
        flex: '1 1 auto',
      }}
    >
      <Flex sx={{ justifyContent: 'space-between' }}>
        <p sx={{ fontWeight: isSelected ? 'bold' : 'normal' }}>
          {moment(option.date).format('dddd D.M.')} {option.label}
        </p>
        <p>{formatPrice(0)}</p>
      </Flex>
    </Box>
  );
};
